<template>
  <div>
    <!-- <img
      style="width: 100%"
      alt=""
      src="@/assets/images/patientInfo.png"
      class="mt-4"
    /> -->
    <div class="ml-10 mr-10">
      <div class="w-full justify-center">
        <div class="vx-row w-full justify-center">
          <div div class="w-full justify-center">
            <VuePhoneNumberInput
              @update="acceptNumber"
              v-model="HospitalModel.Phone"
            />
          </div>
          <div div class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="ContactPerson"
              :label="$t('ContactPerson')"
              class="w-full mt-3"
              icon-no-border
              :placeholder="$t('ContactPerson')"
              v-model="HospitalModel.ContactPersonName"
              :icon-after="true"
            />
          </div>
          <div div class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="ContactPersonEmail"
              class="w-full mt-3"
              :label="$t('ContactPersonEmail')"
              icon-no-border
              :placeholder="$t('ContactPersonEmail') + '*'"
              v-model="HospitalModel.ContactPersonEmail"
              :icon-after="true"
            />
          </div>
          <div div class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="Address"
              class="w-full mt-3"
              :label="$t('Address')"
              icon-no-border
              :placeholder="$t('Address') + '*'"
              v-model="HospitalModel.Address"
              :icon-after="true"
            />
          </div>
          <div div class="w-full justify-center">
            <label class="vs-input--label">{{ $t("Country") }}*</label>
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="Country"
              class="w-full mt-2"
              :placeholder="$t('Country')"
              :icon-after="true"
              label="Name"
              :options="countries"
              :reduce="(ID) => ID.ID"
              v-model="HospitalModel.CountryID"
              @input="getRegionByCountryId(HospitalModel.CountryID)"
            />
          </div>
          <div div class="w-full justify-center">
            <label class="vs-input--label">{{ $t("Provinance") }}*</label>
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="Ragion"
              class="w-full mt-2"
              :placeholder="$t('Provinance')"
              :icon-after="true"
              label="Name"
              :options="regions"
              :reduce="(ID) => ID.ID"
              v-model="HospitalModel.RegionID"
              @input="getCityByRegionId(HospitalModel.RegionID)"
            />
          </div>
          <div div class="w-full justify-center">
            <label class="vs-input--label">{{ $t("City") }}*</label>
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="City"
              class="w-full mt-2"
              :placeholder="$t('City')"
              :icon-after="true"
              label="Name"
              :options="cities"
              :reduce="(ID) => ID.ID"
              v-model="HospitalModel.CityID"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import DataViewSidebar from "../medicalWebinar/DataViewSidebar.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
export default {
  components: {
    //DataViewSidebar,
    VuePhoneNumberInput,
  },
  data() {
    return {
      phonevalue: "",
      options: [],
    };
  },

  methods: {
    acceptNumber(value) {
      debugger;
      // var x = this.hospital.Phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phonevalue = value.e164;
    },
    getRegionByCountryId(CountryId) {
      this.$store
        .dispatch("RegionList/GetAllRegionsByCountryID", CountryId)
        .then(() => {
          if (this.HospitalModel.RegionID > 0) {
            this.getCityByRegionId(this.HospitalModel.RegionID);
          }
        });
    },
    getCityByRegionId(RegionId) {
      this.$store.dispatch("CityList/GetAllCitiesByRegionID", RegionId);
    },
  },
  computed: {
    cities() {
      return this.$store.state.CityList.cities;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    regions() {
      debugger;
      return this.$store.state.RegionList.regions;
    },
  },
  props: {
    HospitalModel: {
      type: Object,
      required: true,
    },
  },
  created() {},
};
</script>
<style>
</style>

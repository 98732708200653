<template>
  <div>
    <img
      style="width: 100%"
      alt=""
      src="@/assets/images/patientInfo.png"
      class="mt-4"
    />
    <div class="ml-10 mr-10">
      <div class="w-full justify-center">
        <div class="vx-row w-full justify-center">
          <div class="mt-2 w-full justify-left">
            <h4>
              {{ $t("Accompanyingperson") }}
            </h4>
          </div>
          <vs-checkbox
            class="mt-1 w-full justify-left"
            v-model="HospitalModel.IsAllowAccompanyingperson"
            >{{
              $t(
                "Forinpatientsanaccompanyingpersonisallowedtostaywiththepatientintheroom"
              )
            }}
          </vs-checkbox>
          <h4 class="justify-center mt-2">
            {{ $t("Facilities") }}
          </h4>
          <vs-row vs-lg="8" vs-sm="12" vs-justify="center" vs-type="flex">
            <vs-col
              v-for="item in facilities"
              :key="item.ID"
              class="justify-left mt-5"
              vs-sm="12"
              vs-lg="6"
              vs-type="flex"
            >
              <vs-row vs-w="12" vs-type="flex">
                <vs-col vs-sm="6" vs-lg="6">
                  <vs-checkbox v-model="item.IsChecked">{{
                    item.Name
                  }}</vs-checkbox>
                </vs-col>
                <vs-col vs-sm="6" vs-lg="6">
                  <img
                    style="width: 50px; height: 20px"
                    alt=""
                    :src="baseURL + item.ImagePath"
                  />
                </vs-col>
              </vs-row>
            </vs-col>
          </vs-row>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import DataViewSidebar from "../medicalWebinar/DataViewSidebar.vue";
import axios from "@/axios.js";
export default {
  components: {
    //DataViewSidebar,
  },
  data() {
    return {
    };
  },

  methods: {
    UpdateHospital() {
      this.hospital.Phone = this.phonevalue;
      this.hospital.HospitalFacilities = this.facilities
        .filter((obj) => obj.IsChecked)
        .map((b) => {
          var h = {};
          h.FacilityID = b.ID;
          return h;
        });

      this.$store
        .dispatch("HospitalList/UpdateHospital", this.hospital)
        .then((res) => {
          if (
            this.$store.state.AppActiveUser.Hospital != null &&
            this.$store.state.AppActiveUser.Hospital.ID > 0
          ) {
            window.showSuccess();
            this.$router.go(-1);
          } else {
            this.$vs.notify({
              color: "success",
              title: "Successfully",
              text: "please wait till you confirm your account, you  will notify by mail",
            });
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    updateCurrImg(input) {
      debugger;
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data).then((response) => {
          this.hospital.ImagePath = response.data;
        });
      }
    },
    AttachmentUploaded(input) {
      debugger;
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data).then((response) => {
          var temp = {};
          temp.AttachPath = response.data;
          this.hospital.HospitalAttachments.push(temp);
        });
      }
    },
    uploadHospitalAttachment(event) {
      debugger;
      var temp = {};
      temp.AttachPath = event.target.responseText;
      this.hospital.HospitalAttachments.push(temp);
    },
  },
  computed: {
    // isFormValid() {
    //   return (
    //     !this.errors.any() &&
    //     this.hospital.NameEN &&
    //     this.hospital.HospitalInfo &&
    //     this.hospital.Address &&
    //     this.hospital.ContactPersonEmail &&
    //     this.hospital.CountryID &&
    //     this.hospital.CityID &&
    //     this.hospital.RegionID
    //   );
    // },
    facilities() {
      debugger;
      return this.$store.state.FacilityList.facilities;
    },
    filterHospitalAttachments() {
      return this.HospitalModel.HospitalAttachments.filter((obj) => obj.ID > 0);
    },
  },
  props: {
    HospitalModel: {
      type: Object,
      required: true,
    },
  },
  created() {
  },
};
</script>
<style></style>

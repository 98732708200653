<template>
  <div>
    <!-- <img style="width: 100%" alt="" src="@/assets/images/patientInfo.png" />
    <div class="ml-10 mr-10">
      <h2>{{ $t("Pleasecompleteyourregistrationtoaccessourservices") }}</h2>
      <div class="ml-10 mr-10 mt-10">
        <point v-for="item in points" :data="item" :key="item.title" />
      </div>
      <div class="w-full justify-center">
        <div class="vx-row w-full con-img justify-center">
          <img
            src="@/assets/images/user.png"
            alt="user-img"
            width="70"
            height="70"
            class="rounded-full shadow-md cursor-pointer block m-4"
          />
          <div class="position: absolute py-12 w-1">
            <input
              type="file"
              class="hidden"
              ref="uploadImgInput"
              @change="updateCurrImg"
              accept="image/*"
              @on-success="successUpload"
            />

            <vs-avatar
              @click="$refs.uploadImgInput.click()"
              color="primary"
              icon-pack="feather"
              icon="icon-upload"
              class="m-5"
            />
          </div>
        </div>
        <div class="vx-row w-full justify-center">
          <div div class="vx-row w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="email"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('HospitalName')"
              v-model="email"
              :icon-after="true"
            />
          </div>
          <div div class="vx-row w-full justify-center">
            <vs-input
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              name="email"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('maincontact')"
              v-model="email"
              :icon-after="true"
            />
          </div>
          <div div class="vx-row w-full justify-center">
            <vs-input
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              name="email"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('PhoneNumber')"
              v-model="email"
              :icon-after="true"
            />
          </div>
          <div div class="vx-row w-full justify-center">
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="email"
              class="w-full sm:w-1/4 xl:w-5/12 m-2 mt-2"
              :placeholder="$t('Country')"
              :icon-after="true"
            />
          </div>
          <div div class="vx-row w-full justify-center">
            <vs-input
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              name="email"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('HospitalGroup')"
              v-model="email"
              :icon-after="true"
            />
          </div>

          <div div class="vx-row w-full justify-center">
            <vs-input
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              name="Speciality"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('Specialty')"
              v-model="email"
              :icon-after="true"
            />
          </div>

          <div class="vx-row w-full">
            <div class="vx-col lg:w-3/4"></div>
            <vs-button
              color="rgb(62, 201, 214) "
              class="vx-col"
              type="filled"
              style="float: right"
              >{{ $t("Next") }}</vs-button
            >
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="vs-row space-between ">
      <vs-button color="rgb(62, 201, 214)  " type="filled">Next</vs-button>
    </div> -->
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab :label="$t('MainInfo')" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <hospital-tab-main class="mt-4" :HospitalModel="hospital" />
            </div>
          </vs-tab>
          <vs-tab
            :label="$t('AdditionalInformation')"
            icon-pack="feather"
            icon="icon-user"
          >
            <div class="tab-text">
              <hospital-tab-additional-information
                class="mt-4"
                :HospitalModel="hospital"
              />
            </div>
          </vs-tab>
          <vs-tab
            :label="$t('PersonInfo')"
            icon-pack="feather"
            icon="icon-user"
          >
            <div class="tab-text">
              <hospital-person-info class="mt-4" :HospitalModel="hospital" />
            </div>
          </vs-tab>
          <vs-tab
            :label="$t('AccompanyingPerson')"
            icon-pack="feather"
            icon="icon-user"
          >
            <div class="tab-text">
              <hospital-accompanying-person
                class="mt-4"
                :HospitalModel="hospital"
              />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <!-- <vs-button v-if="!isFormValid" class="mr-6" @click="ConfirmUser">{{
            $t("Confirm")
          }}</vs-button>
          <vs-button
            class="mr-6"
            @click="submitData"
            :disabled="!isFormValid"
            >{{ $t("Save") }}</vs-button
          > -->
          <vs-button type="border" color="danger" :to="{ name: 'Doctor' }">{{
            $t("Cancel")
          }}</vs-button>
          <vs-button
            :disabled="!isFormValid"
            class="vx-col"
            type="filled"
            style="float: right"
            @click="UpdateHospital"
            >{{ $t("Submit") }}</vs-button
          >
        </div>
        <!-- <div class="w-full text-end mt-5">
         
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import moduleHospital from "@/store/hospital/moduleHospital.js";
import HospitalTabMain from "./HospitalTabMain.vue";
import HospitalTabAdditionalInformation from "./HospitalTabAdditionalInformation.vue";
import HospitalPersonInfo from "./HospitalPersonInfo.vue";
import HospitalAccompanyingPerson from "./HospitalAccompanyingPerson.vue";
import moduleCity from "@/store/settings/city/moduleCity.js";
import moduleRegion from "@/store/settings/region/moduleRegion.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleFacility from "@/store/settings/facility/moduleFacility.js";
import moduleHospitalGroup from "@/store/settings/HospitalGroup/moduleHospitalGroup.js";

export default {
  components: {
    HospitalTabMain,
    HospitalTabAdditionalInformation,
    HospitalPersonInfo,
    HospitalAccompanyingPerson,
    // point
  },
  data() {
    return {
      activeTab: 0,
      options: [],
      radios1: "luis",
      hospital: {
        HospitalAttachments: [],
        HospitalSpecialties: [],
        HospitalFacilities: [],
        ImagePath: "",
      },
    };
  },
  methods: {
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    getRegionByCountryId(CountryId) {
      this.$store
        .dispatch("RegionList/GetAllRegionsByCountryID", CountryId)
        .then(() => {
          if (this.hospital.RegionID > 0) {
            this.getCityByRegionId(this.hospital.RegionID);
          }
        });
    },
    getCityByRegionId(RegionId) {
      this.$store.dispatch("CityList/GetAllCitiesByRegionID", RegionId);
    },
  },
  computed: {
    // isFormValid() {
    //   return (
    //     !this.errors.any()
    //     && this.hospital.NameEN
    //     && this.hospital.HospitalInfo
    //     && this.hospital.Address
    //     && this.hospital.ContactPersonEmail
    //     && this.hospital.CountryID
    //     && this.hospital.CityID
    //     && this.hospital.RegionID
    //   );
    // },
    facilities() {
      return this.$store.state.FacilityList.facilities;
    },
  },
  created() {
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if (!moduleRegion.isRegistered) {
      this.$store.registerModule("RegionList", moduleRegion);
      moduleRegion.isRegistered = true;
    }
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    this.$store.dispatch("CountryList/GetAllCountries");
    if (!moduleFacility.isRegistered) {
      this.$store.registerModule("FacilityList", moduleFacility);
      moduleFacility.isRegistered = true;
    }
    this.$store.dispatch("FacilityList/GetAllFacilities");

    if (!moduleHospitalGroup.isRegistered) {
      this.$store.registerModule("HospitalGroupList", moduleHospitalGroup);
      moduleHospitalGroup.isRegistered = true;
    }
    this.$store.dispatch("HospitalGroupList/GetAllHospitalGroups");

    // Var id = this.$route.params.Id;
    debugger;
    var id = 1063;
    if (id) {
      this.$store.dispatch("HospitalList/GetHospital", id).then((res) => {
        debugger;
        this.hospital = res.data.Data;
        debugger;
        if (this.hospital.CountryID > 0) {
          this.getRegionByCountryId(this.hospital.CountryID);
        }
        if (this.hospital.HospitalFacilities != null) {
          this.hospital.HospitalFacilities.forEach((element) => {
            var index = this.facilities.findIndex(
              (x) => x.ID == element.FacilityID
            );
            debugger;
            if (index >= 0) this.facilities[index].IsChecked = true;
          });
        }
      });
    }
  },
};
</script>
<style>
.textfont {
  font-family: "futuraMedium";
}
</style>

<template>
  <div>
    <img
      style="width: 100%"
      alt=""
      src="@/assets/images/patientInfo.png"
      class="mt-4"
    />
    <div class="ml-10 mr-10">
      <div class="w-full justify-center">
        <div class="vx-row w-full justify-center">
          <div class="w-full justify-left mt-2">
            <h4>
              {{ $t("AdditionalInformation") }}
            </h4>
          </div>
          <div class="justfiy-center w-full mt-3">
            <div>
              <p class="text-align-left">{{ $t("HospitalImages") }}</p>
            </div>
            <div class="vx-row w-full ml-5 mr-5">
              <div
                v-for="item in HospitalModel.HospitalAttachments"
                :key="item.ID"
                class="lg:w-1/4 w-full"
              >
                <div>
                  <div style="position: absolute">
                    <feather-icon
                      icon="XIcon"
                      style="color: red"
                      svgClasses="w-10 h-10 hover:text-primary stroke-current"
                      @click.stop="removeImage(item)"
                    />
                  </div>
                  <div>
                    <img
                      style="border-radius: 10px; opacity: 1"
                      class="p-1"
                      :src="baseURL + item.AttachPath"
                      width="200"
                      height="200"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <input
                type="file"
                ref="uploadImgInput"
                @change="AttachmentUploaded"
                accept="image/*"
                @on-success="successUpload"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import DataViewSidebar from "../medicalWebinar/DataViewSidebar.vue";
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
export default {
  components: {
    //DataViewSidebar,
    quillEditor,
  },
  data() {
    return {
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
    };
  },

  methods: { 
    removeImage(item) {
      var index = this.HospitalModel.HospitalAttachments.findIndex(
        (x) => x.AttachPath == item.AttachPath
      );
      this.HospitalModel.HospitalAttachments.splice(index, 1);
      if (item.ID > 0)
        this.$store.dispatch("HospitalList/DeleteAttachmenByID", item.ID);
    },
    UpdateHospital() {
      HospitalModel.HospitalFacilities = this.facilities
        .filter((obj) => obj.IsChecked)
        .map((b) => {
          var h = {};
          h.FacilityID = b.ID;
          return h;
        });

      this.$store
        .dispatch("HospitalList/UpdateHospital", HospitalModel)
        .then((res) => {
          if (
            this.$store.state.AppActiveUser.Hospital != null &&
            this.$store.state.AppActiveUser.Hospital.ID > 0
          ) {
            window.showSuccess();
            this.$router.go(-1);
          } else {
            this.$vs.notify({
              color: "success",
              title: "Successfully",
              text: "please wait till you confirm your account, you  will notify by mail",
            });
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    AttachmentUploaded(input) {
      debugger;
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);
        debugger;
        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
          var temp = {};
          temp.AttachPath = response.data;
          debugger;
          this.HospitalModel.HospitalAttachments.push(temp);
        });
      }
    },
  },
  computed: {
  },
  props: {
    HospitalModel: {
      type: Object,
      required: true,
    },
  },
  created() {
  },
};
</script>
<style>
</style>

<template>
  <div>
    <img
      style="width: 100%"
      alt=""
      src="@/assets/images/patientInfo.png"
      class="mt-4"
    />
    <div class="ml-10 mr-10">
      <div class="vx-row w-full con-img justify-center">
        <img
          v-if="HospitalModel.ImagePath && HospitalModel.ImagePath != ''"
          :src="baseURL + HospitalModel.ImagePath"
          alt="user-img"
          width="200"
          height="150"
          class="rounded-full shadow-md cursor-pointer block m-4"
        />
        <img
          v-else
          :src="baseURL + 'Uploads/docotrGeneral.jpg'"
          alt="user-img"
          width="200"
          height="150"
          class="rounded-full shadow-md cursor-pointer block m-4"
        />
        <div class="position: absolute py-12 w-1">
          <input
            type="file"
            class="hidden"
            ref="uploadProfile"
            @change="updateCurrImg"
            accept="image/*"
            @on-success="successUpload"
          />
          <vs-avatar
            @click="$refs.uploadProfile.click()"
            color="primary"
            icon-pack="feather"
            icon="icon-upload"
            class="m-12"
          />
        </div>
      </div>
      <h2>
        {{ $t("Pleasecompleteyourregistrationtoaccessourservices") }}
      </h2>
      <div class="w-full justify-center">
        <div class="vx-row w-full justify-center">

          <div div class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="Hospitalname"
              :label="$t('Hospitalname')+'*'"
              class="w-full mt-2"
              icon-no-border
              :placeholder="$t('Hospitalname')"
              v-model="HospitalModel.NameEN"
              :icon-after="true"
            />
          </div>

          <div div class="w-full justify-center">
            <label class="vs-input--label"> {{ $t("HospitalGroup") }}</label>
            <v-select
              v-model="HospitalModel.HospitalGroupID"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="Name"
              label="Name"
              class="w-full mt-2"
              :placeholder="$t('HospitalGroup')"
              ::icon-after="true"
              :options="hospitalGroups"
              :reduce="(ID) => ID.ID"
            />
          </div>

          <div div class="w-full justify-center">
            <label class="vs-input--label"> {{$t('HospitalInfoEN')+'*'}}</label>
             <quill-editor v-model="HospitalModel.HospitalInfoEN" :options="editorOption" />
            
          </div>
          <div div class="w-full justify-center">
            <label class="vs-input--label"> {{$t('HospitalInfoAR')+'*'}}</label>
             <quill-editor v-model="HospitalModel.HospitalInfoAR" :options="editorOption" />
            
          </div>
          <div div class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="HospitalRooms"
              :label="$t('HospitalRooms')"
              class="w-full mt-2"
              icon-no-border
              :placeholder="$t('HospitalRoomsEN')"
              v-model="HospitalModel.RoomDescriptionEN"
              :icon-after="true"
            />
          </div>
          <div div class="w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="HospitalRooms"
              :label="$t('HospitalRooms')"
              class="w-full mt-2"
              icon-no-border
              :placeholder="$t('HospitalRoomsAR')"
              v-model="HospitalModel.RoomDescriptionAR"
              :icon-after="true"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import DataViewSidebar from "@/views/medicalWebinar/DataViewSidebar.vue";
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
export default {
  components: {
    // DataViewSidebar,
    quillEditor,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            [
              "bold",
              "italic",
              "underline",
              "strike",
              "link",
              "blockquote",
              "code-block",
            ],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ font: [] }],
          ],
        },
        placeholder: "Message",
      },
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      options: [],
    };
  },

  methods: {
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      debugger;
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data).then((response) => {
          this.hospital.ImagePath = response.data;
        });
      }
    },
  },
  props: {
    HospitalModel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hospitalGroups() {
      return this.$store.state.HospitalGroupList.HospitalGroups;
    },
  },
};
</script>
